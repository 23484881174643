.drawerHeader {
    display: flex;
    align-items: center;
    color: #668099;
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 30px;

    section {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: start;

        h3 {
            margin: 0px;
            font-size: 1.5rem;
            font-weight: 500;
        }

        p {
            margin: 0px;
            color: #d0e4fb;
            font-size: small;
        }
    }
}

.headerSection {
    display: flex;
    flex-direction: column;
}

.button {
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    color: #28496b;
    margin-bottom: 10px;

    &:hover {
        color: #749ccc;
    }

    p {
        font-size: 1.2rem;
        font-weight: 500;
        margin-left: 1rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        text-align: left;
        text-transform: capitalize;
        cursor: pointer;
    }
}