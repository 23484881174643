.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /* Adjust as needed */
    font-size: 14px;
    margin-left: auto;
    /*   background: linear-gradient(180deg, #e4ecf6 50%, transparent 50%); */

    @media (min-width: 769px) {
        font-size: 16px; // Text size for desktop
    }

    .summary {
        display: flex;
        flex-direction: column;
        margin-top: 1.2rem;
        width: 60%;
        padding: 30px;
        height: 518px;
        /* align-items: center; */

        .summarygroup {
            width: 100%;
            display: flex;
            padding: 30px 0px;
            display: flex;
            justify-content: center;
        }

        .formContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 50px;
            width: 70%;

        }

        .AIModel {
            div::before {
                border-bottom: 2px solid #77a1d3;
            }
        }

        .stepContainer {
            display: flex;
            justify-content: center;
            width: 100%;
        }

        .column {
            display: flex;
            flex-direction: column;
            width: 70%;
            margin-left: 20px;
        }

        .avatar {
            background-color: #ffffff;
            color: #7EA2D6;

            span {
                filter: drop-shadow(2px 3px 4px rgba(0, 0, 0, 0.85));
            }

            box-shadow: 4px 7px 10px 1px rgba(0, 0, 0, 0.53);
        }

        .textField {
            margin-bottom: 16px;
        }


    }

    .createInterview {
        display: flex;
        flex-direction: column;
        border-radius: 15px;
        padding: 0px 30px 37px;
        background-color: #fff;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

        
    }
    .questionContainer{
        display: flex;
        justify-content: end
    }

    .questions{
        display: flex;
        flex-direction: row;
        margin-top: 1.2rem;
        width: 100%;
        padding: 30px;
      
    }

}