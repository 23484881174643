.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /* Adjust as needed */
    font-size: 14px;
    margin-left: auto;

    @media (min-width: 769px) {
      font-size: 16px; // Text size for desktop
    }
  
    .summary {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      width: 100%;
      padding: 30px;
      height: 518px;
  
      .summarygroup {
        width: 100%;
        display: flex;
        padding: 30px 0px;
      }
  
      .createInterview {
        display: flex;
        flex-direction: column;
        border-radius: 15px;
        padding: 25px 15px 20px 15px;
        background-color: #fff;
  
        .title {
          font-size: 1.3rem;
          font-weight: bold;
          margin-bottom: 1rem;
        }
  
        .createInterviewButton {
          padding: 0px;
          width: 30%;
          text-align: center;
          border: none;
          cursor: pointer;
          border-radius: 4px;
          background-color: #4CAF50;
          color: white;
          font-size: 1rem;
          margin-right: 1rem;
        }
      }
  
      h2 {
  
        margin: 0;
        margin-right: 1rem;
      }
  
      .summarycount {
        display: flex;
        flex-direction: column;
        padding-top: 25px;
        font-weight: bold;
        font-size: 3rem;
        margin-right: 1rem;
      }
  
      .statusbuttons {
        display: flex;
  
        button {
          margin-right: 0.5rem;
          padding: 0.5rem 1rem;
          border: none;
          cursor: pointer;
          border-radius: 4px;
  
          &.active {
            background-color: #4CAF50;
            /* Example color */
            color: white;
          }
  
          &.incompleted {
            background-color: #FF5722;
            /* Example color */
            color: white;
          }
        }
      }
    }
  }