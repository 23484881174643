.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: calc(100vh - 64px);
  width: 100%;
  padding: 20px;

  &_center {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    position: relative;
    gap: 20px;
    padding: 60px 40px;
  }
}

.summaryTextarea {
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin: 20px 0;
  padding: 0 20px;
  min-height: 200px;
  margin-bottom: 60px;
  overflow: visible;
  
  & > div {
    overflow: visible !important;
    display: block;
    
    & > div {
      overflow: visible !important;
      display: block;
    }
  }
}

.regenerateButton {
  position: absolute !important;
  right: -45px;
  top: 10px;
}

@media (max-width: 600px) {
  .container_center {
    padding: 40px 20px;
  }

  .regenerateButton {
    position: static !important;
    margin-top: 10px !important;
  }

  .summaryTextarea {
    flex-direction: column;
    align-items: stretch;
    padding: 0 10px;
    margin-bottom: 40px;
  }
}

.video_container {
  margin-top: 30px;
  width: 100%;
  height: 30vh;
  text-align: center;
  overflow: hidden;
}

.videobcg {
  width: inherit;
  height: inherit;
  -o-filter: blur(15px);
  filter: blur(0px);
  object-fit: cover;
  transform: scale(1.04);
}